import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { InformationCircleIcon, ArrowRightIcon, XCircleIcon, CheckCircleIcon, ClipboardCheckIcon } from '@heroicons/react/solid';
import bankOptions from '../data/bankOptions';
import provincias from '../data/Provincias';
import supabase from './services/supabaseClient';
import { UserContext } from './UserContext';

const initialFormData = {
  precioVivienda: '',
  firmasteHipoteca: '',
  viviendaHabitual: '',
  bancoHipoteca: '',
  ingresosBrutosAnuales: '',
  ingresosNetosAnuales: '',
  discapacidad: '',
  mayor60: '',
  dificultadesPago: '',
  grupoFamiliar: '',
  pagoMensualHipoteca: '',
  cantidadPorPagar: '',
  anosFaltantes: '',
  plazoOriginalHipoteca: '',
  interesAnual: '',
  cuotaHace4Anos: '',
  cuotaJunio2022: '',
  ingresosHace4Anos: '',
  nombre: '',
  apellidos: '',
  telefono: '',
  provincia: '',
  politicaPrivacidad: false,
};

function SimulacionCBP() {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [estado, setEstado] = useState('en curso');
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [formData, setFormData] = useState(initialFormData);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [filteredProvinces, setFilteredProvinces] = useState([]);
  const [error, setError] = useState('');
  const [exclusion, setExclusion] = useState(false);
  const [failedCriteria, setFailedCriteria] = useState([]);
  const [vulnerable, setVulnerable] = useState(false);
  const [interesCarencia, setInteresCarencia] = useState(0);
  const [cuotaNuevaCarencia, setCuotaNuevaCarencia] = useState(0);
  const [ahorroCarencia, setAhorroCarencia] = useState(0);
  const [carencia, setCarencia] = useState('');

  const determineScreen = (formData, vulnerable) => {
    const monthlyNetosAnuales = formData.ingresosNetosAnuales / 12;
    const monthlyIngresosHace4Anos = formData.ingresosHace4Anos / 12;

    const currentEffortRate = formData.pagoMensualHipoteca / monthlyNetosAnuales;
    const historicalEffortRate = formData.cuotaHace4Anos / monthlyIngresosHace4Anos;
    const adjustedHistoricalEffortRate = historicalEffortRate * 1.5;
    const effortIncreased = adjustedHistoricalEffortRate <= currentEffortRate;

    let newScreen = '';
    if ((effortIncreased && currentEffortRate >= 0.5) || (vulnerable && currentEffortRate < 1.5)) {
      newScreen = 'carencia5';
    } else if (currentEffortRate < 1.5 && currentEffortRate >= 0.5) {
      newScreen = 'carencia2';
    } else if (currentEffortRate >= 0.3 && currentEffortRate < 0.45) {
      newScreen = 'carencia1';
    } else {
      newScreen = 'exclusion';
    }
    return newScreen;
  };

  useEffect(() => {
    const loadProgress = async () => {
      if (user) {
        const { data, error } = await supabase
          .from('user_progress')
          .select('*')
          .eq('user_id', user.id)
          .single();
        if (data) {
          setFormData(data.formData);
          setCurrentQuestion(data.current_question);
          setEstado(data.estado);
        } else if (error) {
          console.error('Error loading user progress:', error);
        }
      }
      setIsLoading(false);
    };
    loadProgress();
  }, [user]);

  useEffect(() => {
    if (!isLoading) {
      const saveProgress = async () => {
        if (user) {
          const { error } = await supabase
            .from('user_progress')
            .upsert({
              user_id: user.id,
              formData,
              current_question: currentQuestion,
              updated_at: new Date().toISOString(),
              nombre: formData.nombre,
              apellidos: formData.apellidos,
              telefono: formData.telefono,
              estado,
            });
          if (error) {
            console.error('Error saving user progress:', error);
          }

          const { error: journeyError } = await supabase
            .from('customer_journey')
            .upsert({
              user_id: user.id,
              estado: estado,
              timestamp: new Date().toISOString(),
              updated_at: new Date().toISOString()
            }, { onConflict: 'user_id' });

          if (journeyError) {
            console.error('Error updating customer journey:', journeyError);
          }
        }
      };
      saveProgress();
    }
  }, [formData, currentQuestion, user, isLoading, estado]);

  useEffect(() => {
    const newScreen = determineScreen(formData, vulnerable);
    let years = 0;

    let nuevoInteres = 0;
    if (newScreen === 'carencia5') {
      nuevoInteres = 3.6;
      years = 5;
    } else if (newScreen === 'carencia2') {
      nuevoInteres = formData.interesAnual - 0.5;
      years = 2;
    } else if (newScreen === 'carencia1') {
      nuevoInteres = formData.interesAnual - 0.5;
      years = 1;
    }
    setInteresCarencia(nuevoInteres);

    let nuevaCuota = (formData.cantidadPorPagar * (nuevoInteres / 100)) / 12;

    if (newScreen === 'carencia1' && nuevaCuota < formData.cuotaJunio2022) {
      nuevaCuota = formData.cuotaJunio2022;
    }

    setCuotaNuevaCarencia(Math.round(nuevaCuota)); // Redondear a número entero

    const ahorro = (formData.pagoMensualHipoteca - nuevaCuota) * 12 * years;
    setAhorroCarencia(ahorro.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 }));
    setCarencia(newScreen);
  }, [formData, vulnerable]);

  const questions = [
    { label: '¿El precio de tu vivienda es inferior a 300.000€?', name: 'precioVivienda', type: 'radio', options: ['Sí', 'No'], explanation: 'El precio de adquisición de la vivienda debe ser inferior a 300.000€ para cumplir lor requisitos.' },
    { label: '¿Firmaste tu hipoteca antes del 31 de diciembre de 2022?', name: 'firmasteHipoteca', type: 'radio', options: ['Sí', 'No'], explanation: 'Debe haber firmado su hipoteca antes del 31 de diciembre de 2022 para calificar.' },
    { label: '¿Es su vivienda habitual?', name: 'viviendaHabitual', type: 'radio', options: ['Sí', 'No'], explanation: 'Debe ser su vivienda principal y habitual y estar empadronado' },
    { label: '¿Con qué banco tiene la hipoteca? Busca la hipoteca en la lista.', name: 'bancoHipoteca', type: 'autocomplete', options: bankOptions, explanation: 'Seleccione el banco con el que tiene su hipoteca.' },
    { label: '¿Cuáles son los ingresos Brutos Anuales de tu unidad familiar?', name: 'ingresosBrutosAnuales', type: 'number', explanation: 'Suma todos los ingresos que tiene tu unidad familiar durante un año, teniendo en cuenta el importe total que aparece en tu nomina (IRPF, Seguros Seguros Sociales etc.) Son miembros de la unidad famiiar: tu conyuge o pareja de hecho registrada, hijos empadronados en tu vivienda y el resto de titulares de la hipoteca si lo hubiera ' },
    { label: '¿Cuáles son tus ingresos Netos Anuales de tu unidad familiar?', name: 'ingresosNetosAnuales', type: 'number', explanation: 'Suma los ingresos que recibe tu unidad familiar, el líquido durante un año (ingreso en cuenta).' },
    { label: '¿Hay alguien en tu familia que supere una discapacidad del 33%, situación de dependencia o enfermedad que le incapacite acreditadamente de forma permanente para realizar una actividad labora?', name: 'discapacidad', type: 'radio', options: ['Sí', 'No'], explanation: 'Indique si algún miembro de su familia tiene una discapacidad superior al 33% o esta en una situación de dependencia o enfermedad que le incapacite acreditadamente de forma permanente para realizar una actividad labora' },
    { label: '¿Ha tenido dificultades para pagar la cuota de su hipoteca en los últimos 4 años?', name: 'dificultadesPago', type: 'radio', options: ['Sí', 'No'], explanation: 'Indique si ha tenido problemas para pagar la hipoteca en los últimos 4 años.' },
    { label: '¿Su familia pertenece a alguno de estos grupos: familias numerosas, monoparentales, con menores, personas con discapacidad, víctimas de violencia o mayores de 60 años?', name: 'grupoFamiliar', type: 'radio', options: ['Sí', 'No'], explanation: 'Seleccione si su familia pertenece a alguno de estos grupos específicos.' },
    { label: '¿Cuánto pagas de hipoteca al mes?', name: 'pagoMensualHipoteca', type: 'number', explanation: 'Indica cuánto pagas mensualmente por tu hipoteca.' },
    { label: '¿Cuánto dinero te queda por pagar de la hipoteca (cuantia total)?', name: 'cantidadPorPagar', type: 'number', explanation: 'Indica el importe pendiente total, puedes verlo en la app del banco' },
    { label: '¿Cuántos años le faltan para pagar la hipoteca?', name: 'anosFaltantes', type: 'number', explanation: 'Indica cuantos años le faltan para pagar la hipoteca' },
    { label: '¿Cuál es el plazo original de tu hipoteca (indica los años que has elegido inicialmente la hipoteca)?', name: 'plazoOriginalHipoteca', type: 'number', explanation: 'Indica el plazo original de tu hipoteca en años.' },
    { label: '¿Qué interés te estan aplicando actualmente?', name: 'interesAnual', type: 'number', explanation: 'Revisa en la app del banco o en tu ultimo recibo el interes que te estan aplicando en tu cuota, puede ser euribor y un diferencial' },
    { label: '¿Cuánto pagabas mensualmente de cuota hace 4 años?', name: 'cuotaHace4Anos', type: 'number', explanation: 'Indique cuánto pagabas mensualmente de cuota hace 4 años.' },
    { label: '¿Cuánto pagabas de cuota en junio 2022?', name: 'cuotaJunio2022', type: 'number', explanation: 'Necesitamos saber la cuota que pagabas en junio 2022 para calcular la cuota de la carencia.' },
    { label: '¿Cuánto eran tus Ingresos Netos Anuales hace 4 años?', name: 'ingresosHace4Anos', type: 'number', explanation: 'Indique cuánto eran tus ingresos hace 4 años.' },
  ];

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });

    if (name === 'bancoHipoteca') {
      const question = questions.find((q) => q.name === 'bancoHipoteca');
      setFilteredOptions(
        question.options.filter((option) =>
          option.toLowerCase().includes(value.toLowerCase())
        )
      );
      if (!question.options.includes(value)) {
        setError('Por favor seleccione una opción válida.');
      } else {
        setError('');
      }
    }

    if (name === 'provincia') {
      setFilteredProvinces(
        provincias.filter((provincia) =>
          provincia.toLowerCase().includes(value.toLowerCase())
        )
      );
    }

    const newFailedCriteria = [...failedCriteria];
    if (['precioVivienda', 'firmasteHipoteca', 'viviendaHabitual'].includes(name)) {
      if (value === 'No' && !newFailedCriteria.includes(name)) {
        newFailedCriteria.push(name);
      } else if (value === 'Sí' && newFailedCriteria.includes(name)) {
        const index = newFailedCriteria.indexOf(name);
        newFailedCriteria.splice(index, 1);
      }
    }

    if (name === 'ingresosBrutosAnuales' && parseFloat(value) > 37800) {
      if (!newFailedCriteria.includes('ingresosBrutosAnuales')) {
        newFailedCriteria.push('ingresosBrutosAnuales');
      }
    } else if (name === 'ingresosBrutosAnuales' && parseFloat(value) <= 37800) {
      const newFailedCriteriaFiltered = failedCriteria.filter(item => item !== 'ingresosBrutosAnuales');
      setFailedCriteria(newFailedCriteriaFiltered);
      setExclusion(newFailedCriteriaFiltered.length > 0);
    }

    if (['discapacidad', 'mayor60', 'grupoFamiliar'].includes(name)) {
      setVulnerable(value === 'Sí');
    }

    setFailedCriteria(newFailedCriteria);
    setExclusion(newFailedCriteria.length > 0);
  };

  const handleOptionClick = (option) => {
    setFormData({ ...formData, bancoHipoteca: option });
    setFilteredOptions([]);
    setError('');
  };

  const handleProvinceClick = (provincia) => {
    setFormData({ ...formData, provincia });
    setFilteredProvinces([]);
  };

  const handleNext = () => {
    const currentQuestionObj = questions[currentQuestion];
    if (currentQuestionObj.name === 'bancoHipoteca' && !currentQuestionObj.options.includes(formData.bancoHipoteca)) {
      setModalContent('Por favor seleccione una opción válida.');
      setIsModalOpen(true);
      return;
    }
    setCurrentQuestion(currentQuestion + 1);
  };

  const handlePrev = () => {
    setCurrentQuestion(currentQuestion - 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.politicaPrivacidad) {
      setError('Debes aceptar la política de privacidad para continuar.');
      return;
    }

    try {
      const { error } = await supabase
        .from('simulacion_resultados')
        .insert({
          id: user.id,
          email: user.email,
          ...formData,
          Carencia: carencia,
          NuevaCuota: cuotaNuevaCarencia,
          InteresCarencia: interesCarencia
        });

      if (error) {
        throw error;
      }

      const nuevoEstado = 'Lead simulador completo';
      setEstado(nuevoEstado);

      await supabase
        .from('user_progress')
        .upsert({
          user_id: user.id,
          formData,
          current_question: currentQuestion,
          updated_at: new Date().toISOString(),
          nombre: formData.nombre,
          apellidos: formData.apellidos,
          telefono: formData.telefono,
          estado: nuevoEstado,
        });

      await supabase
        .from('customer_journey')
        .upsert({
          user_id: user.id,
          estado: nuevoEstado,
          timestamp: new Date().toISOString(),
          updated_at: new Date().toISOString()
        }, { onConflict: 'user_id' });

    } catch (error) {
      console.error('Error al guardar los datos:', error);
      setError('Hubo un error al guardar los datos. Por favor, inténtalo de nuevo.');
    }
  };

  const handleModalOpen = (content) => {
    setModalContent(content);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setModalContent('');
  };

  const handleRetry = () => {
    window.location.reload();
  };

  const navigateToPricing = async () => {
    const nuevoEstado = 'Lead Pricing';
    setEstado(nuevoEstado);

    await supabase
      .from('user_progress')
      .upsert({
        user_id: user.id,
        formData,
        current_question: currentQuestion,
        updated_at: new Date().toISOString(),
        nombre: formData.nombre,
        apellidos: formData.apellidos,
        telefono: formData.telefono,
        estado: nuevoEstado,
      });

    await supabase
      .from('customer_journey')
      .upsert({
        user_id: user.id,
        estado: nuevoEstado,
        timestamp: new Date().toISOString(),
        updated_at: new Date().toISOString()
      }, { onConflict: 'user_id' });

    const pricingPage = determinePricingPage(formData, vulnerable);
    navigate(pricingPage);
  };

  const determinePricingPage = (formData, vulnerable) => {
    const screen = determineScreen(formData, vulnerable);
    if (screen === 'carencia5') {
      return '/pricing-carencia5';
    } else if (screen === 'carencia2') {
      return '/pricing-carencia2';
    } else if (screen === 'carencia1') {
      return '/pricing-carencia1';
    } else {
      return '/exclusion';
    }
  };

  const progress = (currentQuestion / (questions.length + 1)) * 100;

  const calculateExclusionProgress = () => {
    const totalCriteria = 4;
    const failedCount = failedCriteria.length;
    return ((totalCriteria - failedCount) / totalCriteria) * 100;
  };

  const CarenciaScreen = ({ years, interesCarencia, cuotaNuevaCarencia, pagoMensualHipoteca, interesAnual, ahorroCarencia }) => {
    const dashoffset = { 5: 0, 2: 113.097, 1: 169.646 };

    const cuotaCompleta = cuotaNuevaCarencia >= pagoMensualHipoteca;
    const strokeDashoffsetCuota = cuotaCompleta ? dashoffset[years] : 377 - (377 * (cuotaNuevaCarencia / pagoMensualHipoteca));
    const safeStrokeDashoffsetCuota = isNaN(strokeDashoffsetCuota) ? 0 : strokeDashoffsetCuota;

    const interesCompleto = interesCarencia >= interesAnual;
    const strokeDashoffsetInteres = interesCompleto ? 0 : 377 - (377 * (interesCarencia / interesAnual));
    const safeStrokeDashoffsetInteres = isNaN(strokeDashoffsetInteres) ? 0 : strokeDashoffsetInteres;

    return (
      <div className="text-left bg-gray-100 p-6 rounded-lg shadow-md">
        <p className="text-xl text-gray-700 mb-4">
          Enhorabuena, te puedes acoger al código de buenas prácticas y conseguir una carencia de hasta
          <span className="font-bold"> {years} {years === 1 ? 'año' : 'años'} </span>
          pagando una cuota de
          <span className="font-bold"> {Math.round(cuotaNuevaCarencia)}€/mes</span>.
          Te puedes ahorrar <span className="font-bold"> {ahorroCarencia}€</span>
          durante el periodo de carencia.
        </p>
        <div className="flex flex-col md:flex-row justify-center mb-4">
          <div className="flex flex-col items-center pr-4 mb-4 md:mb-0">
            <div className="border border-gray-300 p-4 rounded-md flex flex-col items-center">
              <span className="text-xl text-gray-700 mb-4 font-bold">Carencia</span>
              <div className="relative w-44 h-44">
                <svg className="absolute top-0 left-0 w-full h-full">
                  <circle className="text-blue-200" strokeWidth="4" stroke="currentColor" fill="transparent" r="60" cx="88" cy="88" />
                  <circle className="text-purple-600" strokeWidth="4" strokeDasharray="377" strokeDashoffset={dashoffset[years]} strokeLinecap="round" stroke="currentColor" fill="transparent" r="60" cx="88" cy="88" />
                  <text x="50%" y="50%" textAnchor="middle" dy=".3em" className="text-4xl font-bold text-gray-700">{years}</text>
                  <text x="50%" y="65%" textAnchor="middle" dy=".3em" className="text-xl text-gray-700 mb-2">{years === 1 ? 'año' : 'años'}</text>
                </svg>
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center pr-4 mb-4 md:mb-0">
            <div className="border border-gray-300 p-4 rounded-md flex flex-col items-center">
              <span className="text-xl text-gray-700 mb-4 font-bold">Nueva cuota</span>
              <div className="relative w-44 h-44">
                <svg className="absolute top-0 left-0 w-full h-full">
                  <circle className="text-blue-200" strokeWidth="4" stroke="currentColor" fill="transparent" r="60" cx="88" cy="88" />
                  <circle className="text-purple-600" strokeWidth="4" strokeDasharray="377" strokeDashoffset={safeStrokeDashoffsetCuota} strokeLinecap="round" stroke="currentColor" fill="transparent" r="60" cx="88" cy="88" />
                  <text x="50%" y="50%" textAnchor="middle" dy=".3em" className="text-3xl font-bold text-gray-700">{Math.round(cuotaNuevaCarencia)}€</text>
                  <text x="50%" y="65%" textAnchor="middle" dy=".3em" className="text-lg text-gray-700 mb-2">/mes</text>
                </svg>
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center pr-4">
            <div className="border border-gray-300 p-4 rounded-md flex flex-col items-center">
              <span className="text-xl text-gray-700 mb-4 font-bold">Interés carencia</span>
              <div className="relative w-44 h-44">
                <svg className="absolute top-0 left-0 w-full h-full">
                  <circle className="text-blue-200" strokeWidth="4" stroke="currentColor" fill="transparent" r="60" cx="88" cy="88" />
                  <circle className="text-purple-600" strokeWidth="4" strokeDasharray="377" strokeDashoffset={safeStrokeDashoffsetInteres} strokeLinecap="round" stroke="currentColor" fill="transparent" r="60" cx="88" cy="88" />
                  <text x="50%" y="50%" textAnchor="middle" dy=".3em" className="text-3xl font-bold text-gray-700">{interesCarencia}%</text>
                  <text x="50%" y="65%" textAnchor="middle" dy=".3em" className="text-lg text-gray-700 mb-2">TAE</text>
                </svg>
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-start items-center mb-4">
          <CheckCircleIcon className="w-10 h-10 text-purple-500 mr-2" />
          <span className="text-lg font-bold">Cumples los requisitos</span>
        </div>
        <div className="flex justify-start items-center mb-4">
          <InformationCircleIcon className="w-10 h-10 text-purple-500 mr-2" />
          <span className="text-lg font-bold">Explicación carencia</span>
          <ArrowRightIcon className="w-6 h-6 text-gray-400 hover:text-gray-600 cursor-pointer ml-2" onClick={() => handleModalOpen('Explicación carencia')} />
        </div>
        <div className="flex justify-start items-center mb-4">
          <ClipboardCheckIcon className="w-10 h-10 text-purple-500 mr-2" />
          <span className="text-lg font-bold">Revisa tus respuestas</span>
          <ArrowRightIcon className="w-6 h-6 text-gray-400 hover:text-gray-600 cursor-pointer ml-2" onClick={() => handleModalOpen('Revisa tus respuestas')} />
        </div>
        <button className="mt-6 bg-purple-600 text-white py-4 px-8 rounded-full text-lg" onClick={navigateToPricing}>
          Acógete ahora y baja la cuota de tu hipoteca
        </button>
      </div>
    );
  };

  const filteredQuestions = questions.filter(q => [
    'bancoHipoteca',
    'ingresosBrutosAnuales',
    'ingresosNetosAnuales',
    'pagoMensualHipoteca',
    'cantidadPorPagar',
    'anosFaltantes',
    'interesAnual',
    'cuotaHace4Anos',
    'cuotaJunio2022',
    'ingresosHace4Anos'
  ].includes(q.name));

  if (isLoading) {
    return <div className="flex min-h-screen bg-gray-50 justify-center items-center">Cargando...</div>;
  }

  return (
    <div className="flex min-h-screen bg-gray-50 overflow-y-auto">
      <div className="flex-grow p-6 mt-16 md:mt-0 flex justify-center items-center">
        <div className="bg-white shadow-lg rounded-lg p-2 w-full max-w-lg md:max-w-2xl lg:max-w-3xl">
          {estado === 'en curso' ? (
            <>
              {exclusion ? (
                <div className="text-center">
                  <h2 className="text-2xl font-bold mb-4 text-red-600">Lo sentimos!</h2>
                  <p className="text-gray-700 mb-4">
                    No cumples los requisitos para acogerte al código de buenas prácticas, consulta los requisitos abajo!
                  </p>
                  <div className="relative w-full h-2 bg-gray-300 rounded-full mb-4">
                    <div className="absolute top-0 left-0 h-2 bg-red-600 rounded-full" style={{ width: `${calculateExclusionProgress()}%` }} />
                  </div>
                  <ul className="space-y-4">
                    <li className="flex items-center">
                      {formData.precioVivienda === 'Sí' ? (
                        <CheckCircleIcon className="w-6 h-6 text-green-500 mr-2" />
                      ) : (
                        <XCircleIcon className="w-6 h-6 text-red-500 mr-2" />
                      )}
                      <span>El precio de su vivienda es inferior o igual a 300.000€</span>
                    </li>
                    <li className="flex items-center">
                      {formData.firmasteHipoteca === 'Sí' ? (
                        <CheckCircleIcon className="w-6 h-6 text-green-500 mr-2" />
                      ) : (
                        <XCircleIcon className="w-6 h-6 text-red-500 mr-2" />
                      )}
                      <span>Firmaste tu hipoteca antes del 31 de diciembre de 2022</span>
                    </li>
                    <li className="flex items-center">
                      {formData.viviendaHabitual === 'Sí' ? (
                        <CheckCircleIcon className="w-6 h-6 text-green-500 mr-2" />
                      ) : (
                        <XCircleIcon className="w-6 h-6 text-red-500 mr-2" />
                      )}
                      <span>Es su vivienda habitual</span>
                    </li>
                    <li className="flex items-center">
                      {formData.ingresosBrutosAnuales <= 37800 ? (
                        <CheckCircleIcon className="w-6 h-6 text-green-500 mr-2" />
                      ) : (
                        <XCircleIcon className="w-6 h-6 text-red-500 mr-2" />
                      )}
                      <span>Los ingresos de la unidad familiar no deben superar los 37800€ anuales</span>
                    </li>
                  </ul>
                  <button onClick={handleRetry} className="mt-6 bg-purple-600 text-white py-2 px-6 rounded-md">
                    Revisar datos
                  </button>
                </div>
              ) : (
                <>
                  <h2 className="text-2xl font-bold mb-4 text-center">Simulación CBP - En curso</h2>
                  <div className="relative w-full h-2 bg-gray-300 rounded-full mb-6">
                    <div className="absolute top-0 left-0 h-2 bg-purple-600 rounded-full" style={{ width: `${progress}%` }} />
                  </div>
                  <form onSubmit={handleSubmit} className="space-y-4">
                    {questions.map((question, index) =>
                      currentQuestion === index && (
                        <div key={question.name}>
                          <div className="flex justify-between items-center mb-4 relative">
                            <label className="block text-gray-700 text-sm md:text-base lg:text-lg">{question.label}</label>
                            <InformationCircleIcon className="w-6 h-6 text-gray-400 hover:text-gray-600 cursor-pointer flex-shrink-0" onClick={() => handleModalOpen(question.explanation)} />
                          </div>
                          {question.type === 'radio' && question.options.map((option) => (
                            <div key={option} className="mb-2">
                              <input type="radio" id={`${question.name}-${option}`} name={question.name} value={option} checked={formData[question.name] === option} onChange={handleChange} className="hidden" />
                              <label htmlFor={`${question.name}-${option}`} className={`block cursor-pointer py-2 px-4 rounded-md border text-center text-sm md:text-base lg:text-lg ${formData[question.name] === option ? 'bg-blue-600 text-white border-blue-600' : 'bg-blue-100 text-blue-600 border-blue-600'}`}>
                                {option}
                              </label>
                            </div>
                          ))}
                          {question.type === 'autocomplete' && (
                            <div className="relative">
                              <input type="text" name={question.name} value={formData[question.name]} onChange={handleChange} className="mt-1 block w-full rounded-md border-gray-300 shadow-sm py-3 px-4 text-sm md:text-base lg:text-lg" placeholder="Escribe para buscar..." />
                              {filteredOptions.length > 0 && (
                                <div className="absolute z-10 w-full bg-white border border-gray-300 rounded-md shadow-lg mt-1 max-h-60 overflow-y-auto">
                                  {filteredOptions.map((option) => (
                                    <div key={option} className="py-2 px-4 hover:bg-gray-100 cursor-pointer text-sm md:text-base lg:text-lg" onClick={() => handleOptionClick(option)}>
                                      {option}
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                          )}
                          {question.type === 'number' && (
                            <input type="number" name={question.name} value={formData[question.name]} onChange={handleChange} className="mt-1 block w-full rounded-md border-gray-300 shadow-sm py-3 px-4 text-sm md:text-base lg:text-lg no-spin" />
                          )}
                          <div className="flex justify-between mt-6">
                            <button type="button" onClick={handlePrev} className={`py-2 px-6 rounded-md text-sm md:text-base lg:text-lg ${currentQuestion === 0 ? 'bg-gray-400 text-gray-700 cursor-not-allowed' : 'bg-purple-600 text-white'}`} disabled={currentQuestion === 0}>
                              Atrás
                            </button>
                            <button type="button" onClick={handleNext} className={`py-2 px-6 rounded-md text-sm md:text-base lg:text-lg ${formData[question.name] ? 'bg-purple-600 text-white' : 'bg-gray-400 text-gray-700 cursor-not-allowed'}`} disabled={!formData[question.name]}>
                              Siguiente
                            </button>
                          </div>
                        </div>
                      ))}
                    {currentQuestion === questions.length && (
                      <div className="space-y-4">
                        <p className="mb-4 text-center text-gray-700 text-sm md:text-base lg:text-lg">
                          Dejános tus datos y sabrás al instante si puedes rebajar la cuota de tu hipoteca.
                        </p>
                        <div className="space-y-2">
                          <input type="text" name="nombre" value={formData.nombre} onChange={handleChange} className="mt-1 block w-full rounded-md border-gray-300 shadow-sm py-3 px-4 text-sm md:text-base lg:text-lg" placeholder="Nombre" />
                          <input type="text" name="apellidos" value={formData.apellidos} onChange={handleChange} className="mt-1 block w-full rounded-md border-gray-300 shadow-sm py-3 px-4 text-sm md:text-base lg:text-lg" placeholder="Apellidos" />
                          <input type="tel" name="telefono" value={formData.telefono} onChange={handleChange} className="mt-1 block w-full rounded-md border-gray-300 shadow-sm py-3 px-4 text-sm md:text-base lg:text-lg" placeholder="Número de Teléfono" />
                          <div className="relative">
                            <input type="text" name="provincia" value={formData.provincia} onChange={handleChange} className="mt-1 block w-full rounded-md border-gray-300 shadow-sm py-3 px-4 text-sm md:text-base lg:text-lg" placeholder="Provincia" />
                            {filteredProvinces.length > 0 && (
                              <div className="absolute z-10 w-full bg-white border border-gray-300 rounded-md shadow-lg mt-1 max-h-60 overflow-y-auto">
                                {filteredProvinces.map((provincia) => (
                                  <div key={provincia} className="py-2 px-4 hover:bg-gray-100 cursor-pointer text-sm md:text-base lg:text-lg" onClick={() => handleProvinceClick(provincia)}>
                                    {provincia}
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="flex items-center mt-4">
                          <input type="checkbox" name="politicaPrivacidad" id="politicaPrivacidad" checked={formData.politicaPrivacidad} onChange={handleChange} className="h-4 w-4 text-purple-600 focus:ring-purple-500 border-gray-300 rounded" />
                          <label htmlFor="politicaPrivacidad" className="ml-2 block text-gray-900 text-sm md:text-base lg:text-lg">
                            Acepto la{' '}
                            <a href="https://codigobuenaspracticas.es/politica-de-privacidad.html" className="text-purple-600 underline" target="_blank" rel="noopener noreferrer">
                              política de privacidad
                            </a>
                          </label>
                        </div>
                        {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
                        <button type="submit" className={`py-2 px-6 rounded-md mt-4 text-sm md:text-base lg:text-lg ${formData.politicaPrivacidad && formData.nombre && formData.apellidos && formData.telefono && formData.provincia ? 'bg-purple-600 text-white' : 'bg-gray-400 text-gray-700 cursor-not-allowed'}`} disabled={!formData.politicaPrivacidad || !formData.nombre || !formData.apellidos || !formData.telefono || !formData.provincia}>
                          Ver el resultado
                        </button>
                      </div>
                    )}
                  </form>
                </>
              )}
            </>
          ) : (
            <>
              {determineScreen(formData, vulnerable) === 'carencia5' && <CarenciaScreen years={5} interesCarencia={interesCarencia} cuotaNuevaCarencia={cuotaNuevaCarencia} ahorroCarencia={ahorroCarencia} />}
              {determineScreen(formData, vulnerable) === 'carencia2' && <CarenciaScreen years={2} interesCarencia={interesCarencia} cuotaNuevaCarencia={cuotaNuevaCarencia} ahorroCarencia={ahorroCarencia} />}
              {determineScreen(formData, vulnerable) === 'carencia1' && <CarenciaScreen years={1} interesCarencia={interesCarencia} cuotaNuevaCarencia={cuotaNuevaCarencia} ahorroCarencia={ahorroCarencia} />}
              {determineScreen(formData, vulnerable) === 'exclusion' && (
                <div className="text-center">
                  <h2 className="text-2xl font-bold mb-4 text-red-600">Lo sentimos!</h2>
                  <p className="text-gray-700 mb-4">
                    No cumples los requisitos para acogerte al código de buenas prácticas, consulta los requisitos abajo!
                  </p>
                  <ul className="space-y-4 text-left">
                    <li><strong>Precio de la vivienda:</strong> {formData.precioVivienda}</li>
                    <li><strong>Firmaste tu hipoteca antes del 31 de diciembre de 2022:</strong> {formData.firmasteHipoteca}</li>
                    <li><strong>Vivienda habitual:</strong> {formData.viviendaHabitual}</li>
                    <li><strong>Banco de la hipoteca:</strong> {formData.bancoHipoteca}</li>
                    <li><strong>Ingresos brutos anuales:</strong> {formData.ingresosBrutosAnuales}</li>
                    <li><strong>Discapacidad del 33%:</strong> {formData.discapacidad}</li>
                    <li><strong>Mayor de 60 años:</strong> {formData.mayor60}</li>
                    <li><strong>Grupo familiar:</strong> {formData.grupoFamiliar}</li>
                    <li><strong>Pago mensual de hipoteca:</strong> {formData.pagoMensualHipoteca}</li>
                    <li><strong>Cantidad por pagar:</strong> {formData.cantidadPorPagar}</li>
                    <li><strong>Años faltantes para pagar la hipoteca:</strong> {formData.anosFaltantes}</li>
                    <li><strong>Interés anual de la hipoteca:</strong> {formData.interesAnual}</li>
                    <li><strong>Cuota hace 4 años:</strong> {formData.cuotaHace4Anos}</li>
                    <li><strong>Ingresos hace 4 años:</strong> {formData.ingresosHace4Anos}</li>
                    <li><strong>Nombre:</strong> {formData.nombre}</li>
                    <li><strong>Apellidos:</strong> {formData.apellidos}</li>
                    <li><strong>Número de Teléfono:</strong> {formData.telefono}</li>
                    <li><strong>Provincia:</strong> {formData.provincia}</li>
                  </ul>
                  <div className="mt-4">
                    <p><strong>La cuota de tu hipoteca es inferior al 30% de tus ingresos y no perteneces a una familia vulnerable.</strong></p>
                    <p>Una familia vulnerable se define como aquella que tiene algún miembro con una discapacidad superior al 33%, es mayor de 60 años o pertenece a grupos específicos como familias numerosas, monoparentales, con menores, personas con discapacidad o víctimas de violencia.</p>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white p-6 rounded-md shadow-md w-full max-w-md relative overflow-y-auto max-h-full">
            <button onClick={handleModalClose} className="absolute top-2 right-2 text-gray-600 hover:text-gray-900 focus:outline-none">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
            <h3 className="text-xl font-bold mb-4">Más Información</h3>
            <div className="mb-4">
              <p>{modalContent}</p>
              {modalContent === 'Explicación carencia' && (
                <div>
                  <div className="wistia_responsive_padding" style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                    <div className="wistia_responsive_wrapper" style={{ height: '100%', left: 0, position: 'absolute', top: 0, width: '100%' }}>
                      <iframe src="https://fast.wistia.net/embed/iframe/tef64ulxos?seo=true&videoFoam=true" title="video para explicar el simulador_1" allow="autoplay; fullscreen" allowtransparency="true" frameBorder="0" scrolling="no" className="wistia_embed" name="wistia_embed" msallowfullscreen="true" width="100%" height="100%"></iframe>
                    </div>
                  </div>
                  <script src="https://fast.wistia.net/assets/external/E-v1.js" async></script>
                  <div className="mt-8">
                    <h4 className="text-lg font-bold mb-4">Contestamos a tus preguntas:</h4>
                    <div className="space-y-4">
                      <details className="p-4 border rounded-md">
                        <summary className="font-semibold">¿Entonces si pago intereses, no pago capital?</summary>
                        <p className="mt-2">
                          Durante el plazo de la carencia solo pagas intereses para aliviar realmente la carga que tienes con la cuota hipotecaria, pero lo más importante es lo siguiente:
                          <ul className="list-disc pl-5">
                            <li>El tipo de interés se reduce en un 0,10% sobre el euribor para las carencias de cinco años y un 0,5% sobre el interés actual para carencias a uno o dos años.</li>
                            <li>No estás obligado a ampliar el plazo de amortización de tu hipoteca.</li>
                          </ul>
                          Gracias a estos dos puntos y al sistema de amortización francés (cada mes que transcurre pagas más de principal y menos de intereses) conseguirás ahorrar un gran capital por los intereses durante toda la vida del préstamo hipotecario.
                        </p>
                      </details>
                      <details className="p-4 border rounded-md">
                        <summary className="font-semibold">¿Qué es una carencia, no lo entiendo?</summary>
                        <p className="mt-2">
                          La carencia es una medida que te permite pagar mucho menos durante el plazo de uno, dos o cinco años. Durante ese tiempo sólo pagas intereses, además de obtener una reducción del tipo de interés, en concreto un 0,10% sobre el euribor para las carencias de cinco años y un 0,5% sobre el interés actual para el resto de carencias. Esta carencia no te obliga a ampliar el plazo de tu hipoteca por lo que así reduces el tipo de interés considerablemente, obteniendo un ahorro elevado en el importe total del préstamo hipotecario.
                        </p>
                      </details>
                      <details className="p-4 border rounded-md">
                        <summary className="font-semibold">¿Entonces si tengo una carencia, tengo que ampliar la hipoteca?</summary>
                        <p className="mt-2">
                          No, la ampliación es opcional, por lo que existe la posibilidad de ampliar tu hipoteca siete años si la carencia es de uno o dos años o hasta un total de cuarenta años para carencias de cinco años.
                        </p>
                      </details>
                      <details className="p-4 border rounded-md">
                        <summary className="font-semibold">¿Los gastos del notario lo asume el banco?</summary>
                        <p className="mt-2">
                          Los gastos de notaría aparejados al código de buenas prácticas deben ser asumidos 100% por el banco tal como establece la normativa actual y no cabe negociación al respecto.
                        </p>
                      </details>
                      <details className="p-4 border rounded-md">
                        <summary className="font-semibold">¿Qué pasa con el dinero que no se paga? ¿Lo asume el gobierno o lo paga el banco?</summary>
                        <p className="mt-2">
                          El dinero que se perdona o exonera con este proceso es una pérdida que debe asumir la entidad, ya que están obligados a aplicar estas medidas de forma directa sin contraprestación alguna por ello.
                        </p>
                      </details>
                      <details className="p-4 border rounded-md">
                        <summary className="font-semibold">¿Qué pasa si mejora mi situación durante la carencia?</summary>
                        <p className="mt-2">
                          En ese caso las medidas se mantienen vigentes de forma inamovible, por lo que no perderás los derechos adquiridos anteriormente.
                        </p>
                      </details>
                      <details className="p-4 border rounded-md">
                        <summary className="font-semibold">¿Se puede hacer la carencia más de una vez?</summary>
                        <p className="mt-2">
                          Puedes realizar una segunda solicitud si el motivo de su solicitud no sea la imposibilidad de pagar la nueva cuota establecida tras la carencia, es decir que si después de disfrutar de una carencia te resultase imposible pagar la nueva cuota resultante del sistema francés, sí podrías solicitar una ampliación del periodo de carencia o incluso una segunda carencia si se dan las circunstancias anteriormente descritas.
                        </p>
                      </details>
                      <details className="p-4 border rounded-md">
                        <summary className="font-semibold">¿Cuánto dura el proceso?</summary>
                        <p className="mt-2">
                          Una vez que la entidad bancaria reciba tu solicitud tiene un plazo máximo de un mes para informarte si cumples los requisitos y aplicarte la carencia si corresponde. Si pasado un mes no recibes respuesta, puedes entender que tu solicitud ha sido denegada por silencio administrativo.
                        </p>
                      </details>
                      <details className="p-4 border rounded-md">
                        <summary className="font-semibold">¿Qué debo hacer si el banco no responde?</summary>
                        <p className="mt-2">
                          Si pasado un mes no recibes respuesta a tu solicitud puedes entender que tu solicitud ha sido denegada por silencio administrativo. En este caso, puedes interponer una reclamación al servicio de atención al cliente de tu entidad bancaria y, posteriormente, si fuera necesario, al Banco de España.
                        </p>
                      </details>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SimulacionCBP;
